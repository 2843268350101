import React from "react";
import "./App.scss";

function App() {
  return (
    <div
      className="w-full h-full bg-cover bg-center absolute"
      style={{
        backgroundImage: `url('/bg-image.jpg')`,
      }}
    >
      <div className="bg-black bg-opacity-80 w-full h-full flex flex-col gap-3 items-center justify-center">
        <div className="glass flex flex-col gap-3 items-center justify-center bg-black-800/60 p-20 rounded-3xl">
          <img src="/logo.svg" alt="logo" className="w-96" />
          <h1 className="text-white text-6xl font-bold">
            Build your dreams...
          </h1>
        </div>
      </div>
    </div>
  );
}

export default App;
